var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-100 w-100",attrs:{"id":"manage"}},[_c('div',{staticClass:"wrapper w-100 overflow-auto"},[_c('Sidebar'),_c('div',{staticClass:"main-panel"},[_c('Header'),_c('div',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('BeeGridTable',{attrs:{"border":"","columns":_vm.columns,"data":_vm.data,"highlight-row":""},scopedSlots:_vm._u([{key:"roleName",fn:function(ref){
var row = ref.row;
return [(row.checkMasterRoom)?_c('div',[_c('i',{staticClass:"fas fa-user-check",staticStyle:{"float":"right","color":"#3CB371"}}),_vm._v(_vm._s(row.roleName)+" ")]):_c('div',[_vm._v(" "+_vm._s(row.roleName)+" "),_c('i',{staticClass:"fas fa-user-check",staticStyle:{"float":"right","visibility":"hidden"}})])]}},{key:"active",fn:function(ref){
var row = ref.row;
return [(row.active)?_c('div',{staticClass:"activated"},[_c('i',{staticClass:"fa fa-check-circle",staticStyle:{"font-size":"14px"}})]):_c('div',{staticClass:"inactivated"},[_c('i',{staticClass:"far fa-times-circle",staticStyle:{"font-size":"14px"}})])]}},{key:"op",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [(row.username != _vm.user.username)?_c('div',[_c('Button',{staticStyle:{"margin-right":"5px"},attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.handleUpdate(row, index)}}},[_vm._v("編集")]),_c('Button',{attrs:{"type":"error","size":"small"},on:{"click":function($event){return _vm.handleDelete(row, index)}}},[_vm._v("削除")]),(_vm.session.includes(row.operator))?_c('Button',{staticClass:"online",on:{"click":function($event){return _vm.deleteSession(row.operator)}}},[_vm._v("オンライン ")]):_c('Button',{staticClass:"offline1"},[_vm._v("オフライン ")])],1):_vm._e()]}}])},[_c('template',{slot:"hop"},[_vm._v("操作")])],2)],1)]),_c('Footer')],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div id="manage" class="h-100 w-100">
    <div class="wrapper w-100 overflow-auto">
      <Sidebar> </Sidebar>
      <div class="main-panel">
        <Header />
        <div class="content">
          <div class="container-fluid">
            <BeeGridTable
              border
              :columns="columns"
              :data="data"
              highlight-row
            >
              <template slot-scope="{ row }" slot="roleName">
                <div v-if="row.checkMasterRoom">
                  <i class="fas fa-user-check" style="float:right; color: #3CB371"></i>{{row.roleName}}
                </div>
                <div v-else>
                  {{row.roleName}} <i class="fas fa-user-check" style="float:right;visibility:hidden;"></i>
                </div>
                
              </template> 
              <template slot-scope="{ row }" slot="active">
                <div class="activated" v-if="row.active">
                  <i class="fa fa-check-circle" style="font-size: 14px"></i>
                </div>
                <div class="inactivated" v-else>
                  <i class="far fa-times-circle" style="font-size: 14px"></i>
                </div>
              </template>
              <template slot="hop">操作</template>
              <template slot-scope="{ row, index }" slot="op">
                <div v-if="row.username != user.username">
                  <!-- <div style="padding-top:3px;"> -->
                  <Button
                    type="primary"
                    size="small"
                    style="margin-right: 5px"
                    @click="handleUpdate(row, index)"
                    >編集</Button
                  >
                  <!-- </div> -->
                  <!-- <div style="padding-top:3px;" v-if="row.username != user.username"> -->
                  <Button
                    type="error"
                    size="small"
                    @click="handleDelete(row, index)"
                    
                    >削除</Button
                  >
                  <!-- v-if="row.username == session.id" -->
                  <Button class="online" v-if="session.includes(row.operator)"
                    @click="deleteSession(row.operator)"
                    >オンライン
                  </Button>
                  <Button class="offline1" v-else
                    >オフライン
                  </Button>
                  <!-- </div> -->
                </div>
              </template>
              <!-- <template slot-scope="{ row, index }" slot="expand">
                <div>ID:{{ row.id }}</div>
                <div>UserName: {{ row.username }}</div>
                <div>Name: {{ row.name }}</div>
                <div>
                  <span> Detail: </span>
                  <span>{{
                    row.name + " " + row.building + " " + row.door
                  }}</span>
                </div>
                <Button type="warning" size="small" style="margin-right: 5px"
                  >Edit</Button
                >
                <Button
                  type="error"
                  size="small"
                  @click="handleDelete(row, index)"
                  >Delete</Button
                >
              </template> -->
            </BeeGridTable>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  </div>
</template>

<script >
import Sidebar from "../dashboard/Sidebar.vue";
import Header from "../dashboard/Header.vue";
import Footer from "../dashboard/Footer.vue";
import axios from "axios";

axios.defaults.headers.post["Content-Type"] = "application/json";
export default {
  name: "dashboard",
  components: {
    Header,
    Sidebar,
    Footer,
  },

  data() {
    return {
      OPENVIDU_SERVER_URL: process.env.VUE_APP_OPENVIDU_SERVER_URL,
      OPENVIDU_SERVER_SECRET: process.env.VUE_APP_OPENVIDU_SERVER_SECRET,
      user: [],
      data: [],
      session: [],
      timer: "",
      columns: [
        // {
        //   type: "expand",
        //   hideFilter: true,
        //   slot: "expand",
        //   width: 50,
        // },
        // {
        //   title: "ID",
        //   key: "id",
        //   align: "center",
        //   resizable: true,
        //   sortable: true,
        // },
        {
          title: "ユーザーＩＤ",
          key: "username",
          align: "center",
          sortable: true,
          sortType: "desc",
        },
        {
          title: "名前",
          key: "name",
          align: "center",
          sortable: true,
        },
        {
          title: "役割名",
          slot: "roleName",
          key: "roleName",
          align: "center",
          sortable: true,
        },
        {
          title: "室名",
          key: "operator",
          align: "center",
          sortable: true,
        },
        {
          title: "更新日",
          key: "updateDate",
          align: "center",
          type: "date", //date field
          dateType: "datetime", //date、datetime、year、month
          format: "yyyy-MM-dd", //yyyy-MM-dd 、 hh:mm、HH:mm:ss ...
          sortable: true,
        },
        {
          title: "作成日",
          key: "createDate",
          align: "center",
          type: "date", //date field
          dateType: "datetime", //date、datetime、year、month
          format: "yyyy-MM-dd", //yyyy-MM-dd 、 hh:mm、HH:mm:ss ...
          sortable: true,
        },
        {
          title: "ステータス",
          slot: "active",
          key: "active",
          width: 130,
          resizable: true,
        },
        {
          title: "",
          key: "op",
          slot: "op",
          headSlot: "hop",
        },
      ],
    };
  },
  // computed : {

  // },
  created() {
    // check realtime connection network timer 1 second
    this.fetchEventsConnectNetwork();
    this.timer = setInterval(this.fetchEventsConnectNetwork, 1000);
  },
  async mounted() {
    $("body").css({
      background:
        "linear-gradient(45deg, rgba(101, 31, 87, 1) 0%,rgba(225, 113, 87, 1) 48%,rgba(249, 248, 113, 1) 100%)",
    });
    $('.main-panel>.content').css('margin-top',50);
    // $(".activate").css({
    //   width: "92px",
    //   height: "auto",
    //   // border-radius: "5px",
    // });
    if (!this.$cookies.isKey("token")) {
      this.$router.push("/login");
    } else {
      var token = this.$cookies.get("token");
      await this.checkAuthen(token).then((data) => {
        if (data != null) {
          this.user = data;
          if (this.user.roleId != 1) {
            this.logoutSesion();
            this.$router.push({
              path: "/login",
              query: { message: "ユーザーＩＤは管理者ではありません" },
            });
            // this.$cookies.remove("token");
            // this.$cookies.remove("usertoken");
          } else {
            if (!this.user.active) {
              this.logoutSesion();
              this.$router.push({
                path: "/login",
                query: { message: "ユーザーＩＤがアクティブではありません" },
              });
              // this.$cookies.remove("token");
              // this.$cookies.remove("usertoken");
            } else {
              this.getAllSession();
              this.getListUser(token);              
            }
          }
        } else {
          this.logoutSesion();
          this.$router.push("/login");
          // this.$cookies.remove("token");
          // this.$cookies.remove("usertoken");
        }
      });
    }
  },

  methods: {
    logout() {
      // this.$cookies.remove("token");
      // this.$cookies.remove("usertoken");
      this.logoutSesion();
      this.$router.push("/login");
    },
    logoutSesion(){
      var token = this.$cookies.get("token");
      // if(token!= null || token != ''){
      //   this.delAuthen(token);
      // }
      this.$cookies.remove("token");
      this.$cookies.remove("usertoken");
    },
    delAuthen(tokenid) {
      axios
        .post(`${this.OPENVIDU_SERVER_URL}/vti/delAuthen`, {
          token: tokenid,
        })
        .then((response) => {
          if (response.status === 200) {
            if (response.data == "success") {
              this.flg = true;
            }
          }
        })

        .catch((error) => console.log(error));
    },
    isActive(menuItem) {
      return this.$router.currentRoute.name === menuItem;
    },
    async fetchEventsConnectNetwork() {
      await this.getAllSession();
    },
    getListUser(tokenclient) {
      axios
        .post(`${this.OPENVIDU_SERVER_URL}/vti/getListUser`, {
          token: tokenclient,
        })
        .then((response) => {
          if (response.status === 200) {
            this.data = response.data;
            // if(this.user)
          }
        })

        .catch((error) => console.log(error));
    },
    checkAuthen(tokenclient) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${this.OPENVIDU_SERVER_URL}/vti/authentication`, {
            token: tokenclient,
          })
          .then((response) => {
            if (response.status === 200) {
              if (response.data != null) {
                resolve(response.data);
              } else {
                reject(error.response);
              }
            }
          })
          .catch((error) => {
            reject(error.response);
            this.$router.go(0);
          });
      });
    },

    async getAllSession(){
      axios
        .get(`${this.OPENVIDU_SERVER_URL}/openvidu/api/sessions`, {
          auth: {
            username: "OPENVIDUAPP",
            password: this.OPENVIDU_SERVER_SECRET,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            const list =[];
            this.session = [];
            for(var i =0; i<response.data.content.length;i++){
              list.push(response.data.content[i].id);
            }
            this.session = list;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteSession(sessionId) {
      this.$confirm("ログインセッションを削除しますか？").then(() => {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `${this.OPENVIDU_SERVER_URL}/openvidu/api/sessions/${sessionId}`,
            {
              auth: {
                username: "OPENVIDUAPP",
                password: this.OPENVIDU_SERVER_SECRET,
              },
            }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response);
            this.$router.go(0);
          });
      });
      });
    },
    handleDelete(row, index) {
      this.$confirm("データを削除しますか？").then(() => {
        var token = this.$cookies.get("token");
        this.deleteUser(token, row.id);
        const i = this.data.findIndex((p) => p.id == row.id);
        if (i >= 0) {
          this.data.splice(i, 1);
        }
      });
    },
    deleteUser(tokenclient, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${this.OPENVIDU_SERVER_URL}/vti/deleteUser`, {
            id,
            token: tokenclient,
          })
          .then((response) => {
            if (response.status === 200) {
              if (response.data != "fail") {
                // this.$alert("Success");
              } else {
                // this.$alert("Fail");
              }
            }
          })
          .catch((error) => {
            reject(error.response);
            this.$router.go(0);
          });
      });
    },
    handleUpdate(row, index) {
      this.$router.push({ path: "/updateUser", query: { userid: row.id } });
    },
  },
  beforeDestroy() {
    var token = this.$cookies.get("token");
      // if(token!= null || token != ''){
      //   this.delAuthen(token);
      // }
  },
};
</script>
<style lang="scss" scoped>
</style>
<style >
@import "../assets/css/material-dashboard.css";
@import "../assets/css/css.css";
/* @import "../assets/css/font-awesome.min.css"; */
@import "../assets/demo/demo.css";
@import "../assets/scss/abstracts/_fonts.scss";

html {
  height: 100%;
  overflow: hidden;
}

body {
  border: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  /* background-color: #fff; */
  /* background: linear-gradient(
    45deg,
    rgba(101, 31, 87, 1) 0%,
    rgba(225, 113, 87, 1) 48%,
    rgba(249, 248, 113, 1) 100%
  ); */
}
/* #manage{
  background: linear-gradient(
    45deg,
    rgba(101, 31, 87, 1) 0%,
    rgba(225, 113, 87, 1) 48%,
    rgba(249, 248, 113, 1) 100%
  ) !important;
} */
.activated {
  height: auto;
  border-radius: 5px;
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  position: relative;
  padding: 12px 30px;
  margin: 0.3125rem 1px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.428571;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0;
  text-align: center;
  box-shadow: 0 2px 2px 0 rgb(153 153 153 / 14%),
    0 3px 1px -2px rgb(153 153 153 / 20%), 0 1px 5px 0 rgb(153 153 153 / 12%);
}
.inactivated {
  height: auto;
  border-radius: 5px;
  color: #fff;
  position: relative;
  padding: 12px 27px;
  margin: 0.3125rem 1px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.428571;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0;
  text-align: center;
  box-shadow: 0 2px 2px 0 rgb(153 153 153 / 14%),
    0 3px 1px -2px rgb(153 153 153 / 20%), 0 1px 5px 0 rgb(153 153 153 / 12%);
  background-color: #f44336;
  border-color: #f44336;
}

.online {
  height: auto;
  border-radius: 3px;
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  position: relative;
  font-weight: 400;
  line-height: 1.428571;
  text-decoration: none;
  letter-spacing: 0;
  text-align: center;
  box-shadow: 0 2px 2px 0 rgb(153 153 153 / 14%),
    0 3px 1px -2px rgb(153 153 153 / 20%), 0 1px 5px 0 rgb(153 153 153 / 12%);
  margin-left: .52rem;
  font-size: 1.35rem;
  padding-top: .2rem;
  padding-bottom: .2rem;
  padding-left: .5rem;
  padding-right: .5rem;
}
.offline1 {
  height: auto;
  border-radius: 3px;
  color: #fff;
  position: relative;
  font-weight: 400;
  line-height: 1.428571;
  text-decoration: none;
  pointer-events: none; 
  letter-spacing: 0;
  text-align: center;
  box-shadow: 0 2px 2px 0 rgb(153 153 153 / 14%),
    0 3px 1px -2px rgb(153 153 153 / 20%), 0 1px 5px 0 rgb(153 153 153 / 12%);
  background-color: #A9A9A9;
  border-color: #A9A9A9;
  margin-left: .52rem;
  font-size: 1.35rem;
  padding-top: .2rem;
  padding-bottom: .2rem;
  padding-left: .5rem;
  padding-right: .5rem;
}
  @media (max-width: 1194px) {
    .offline1{
      margin-left: 0px;
    }
    .online{
      margin-left: 0px;
    }
  }
.logo {
  align-self: center;
  color: #fff;
  font-weight: bold;
  font-family: "Lato";
}

.main-nav {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0.8rem;
}

ul.sidebar-panel-nav {
  list-style-type: none;
}

ul.sidebar-panel-nav > li > a {
  color: #fff;
  text-decoration: none;
  font-size: 1.5rem;
  display: block;
  padding-bottom: 0.5em;
}
.bee-table-cell{
  overflow: visible;
}
@media only screen and (device-width: 768px) {
  /* For general iPad layouts */
  .bee-table-header tr th {
    overflow-x: hidden !important;
    font-size: 10px;
    text-decoration: bold;
    text-align: center;
    padding: 0;
  }

  .bee-table-header tr th .bee-table-cell {
    padding: 0;
  }
}
</style>